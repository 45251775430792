/* NavBar.css */
.NavBar-Container {
    background-color: #333;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .title{
    font-size: 1.2em;
    font-weight: bold;
    margin-right: 15px;
    margin-left: 15px;
    background-color: orange;
    width: 135px;
    border-radius: 5px;
  }

  .nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .nav-item {
    margin-right: 15px;
  }
  
  .nav-link {
    color: black;
    text-decoration: none;
    font-size: 1.2em;
  }
  
  .nav-link:hover {
    border-bottom: 2px solid orange;
  }

  .nav-item-search{
    border-radius: 5px;
  }